<template>
  <div class="pagination">
    <!-- link to previous page -->
    <template v-if="isInFirstPage">
      <a class="cvr-bg-bf pointer ic-arrow-left inactive"></a>
    </template>
    <template v-else>
      <a
        class="cvr-bg-bf pointer ic-arrow-left"
        @click="onClickPreviousPage"
      ></a>
    </template>
    <!-- end link to previous page -->
    <!-- looping page -->
    <template v-for="(page, index) in pages">
      <template v-if="page.isDisabled">
        <span :key="index">{{ page.number }}</span>
      </template>
      <template v-else>
        <a class="pointer" @click="onClickPage(page.number)" :key="index">
          {{ page.number }}
        </a>
      </template>
    </template>
    <!-- end looping page -->
    <!-- link to next page -->
    <template v-if="isInLastPage">
      <a class="cvr-bg-bf pointer ic-arrow-right inactive"></a>
    </template>
    <template v-else>
      <a class="cvr-bg-bf pointer ic-arrow-right" @click="onClickNextPage"></a>
    </template>
    <!-- end link to next page -->
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }
      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          number: i,
          isDisabled: i === this.currentPage
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    }
  },
  methods: {
    onClickFirstPage() {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      window.scrollTo(0, 0);
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
};
</script>
