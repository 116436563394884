<template>
  <div id="article-page">
    <div
      class="banner cvr-bg"
      style="background-image: url('/img/dummy/about/article/1.jpg');"
    >
      <div class="wrapper">
        <h1>{{ $t("aboutUs.Article") }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-sidebar">
      <div class="wrapper rowflex">
        <sidebar-about></sidebar-about>
        <article class="main wider">
          <div class="inner-article">
            <div class="format-text">
              <p>
                Check out where we have been featured and our own articles here:
              </p>
            </div>
            <!-- end of format text -->
            <br />
            <div class="list rowflex">
              <template v-for="(article, index) in rows">
                <div class="item" :key="index">
                  <figure>
                    <router-link :to="`/about-us/article/${article.slug}`">
                      <img :src="article.cover" />
                    </router-link>
                  </figure>
                  <div class="caption">
                    <h3>
                      <router-link :to="`/about-us/article/${article.slug}`">
                        {{ article.title }}
                      </router-link>
                    </h3>
                    <span>{{ article.date | date_only }}</span>
                    <div class="format-text">
                      <p v-html="article.description"></p>
                    </div>
                    <!-- end of format text -->
                    <router-link
                      :to="`/about-us/article/${article.slug}`"
                      class="more cvr-bg-af"
                    >
                      {{ $t("aboutUs.DetailArticle") }}
                    </router-link>
                  </div>
                  <!-- end of caption -->
                </div>
                <!-- end of item -->
              </template>
            </div>
            <!-- end of list -->
            <pagination
              :total-pages="totalPages"
              :total="totalPages"
              :per-page="perPage"
              :current-page="currentPage"
              @pagechanged="onPageChange"
            ></pagination>
            <!-- end of pagination -->
          </div>
          <!-- end of inner article -->
        </article>
        <!-- end of main -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner aside -->
  </div>
</template>

<script>
import SidebarAbout from "./SidebarAbout";
import Pagination from "@/components/Pagination";
export default {
  name: "Article",
  metaInfo: {
    title: "Article"
  },
  components: {
    SidebarAbout,
    Pagination
  },
  data() {
    return {
      rows: [],
      currentPage: 1,
      perPage: 9,
      totalData: 1,
      totalPages: 1
    };
  },
  watch: {
    currentPage() {
      this.getArticle();
    }
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    getArticle() {
      this.$axios
        .get(`/articles`, {
          params: {
            page: this.currentPage,
            limit: this.perPage,
            sortBy: "date.desc"
          }
        })
        .then(response => {
          this.rows = response.data.data.rows;
          this.totalData = response.data.data.count;
          this.totalPages = response.data.data.totalPages;
        });
    },
    onPageChange(page) {
      this.currentPage = page;
    }
  }
};
</script>
